<template>
  <aside
    class="sidebar"
    :class="{ 'sidebar-closed': !isOpen }"
    role="complementary"
    aria-label="Saved checks sidebar"
  >
    <button
      class="sidebar-toggle mobile-sidebar-toggle"
      @click="$emit('toggle')"
      aria-label="Toggle sidebar"
    >
      <PanelLeftClose v-if="isOpen" />
      <PanelLeftOpen v-else />
    </button>
    <h2 class="sidebar-title" id="saved-checks-title">Saved Checks</h2>
    <div
      class="saved-chats"
      role="navigation"
      aria-labelledby="saved-checks-title"
      @keydown.up.prevent="handleKeyNavigation(-1)"
      @keydown.down.prevent="handleKeyNavigation(1)"
      @keydown.home.prevent="handleFirstFocus"
      @keydown.end.prevent="() => handleKeyNavigation(Infinity)"
      tabindex="0"
    >
      <template v-if="savedChats.today.length">
        <h3 id="today-checks">Today</h3>
        <div
          v-for="chat in savedChats.today"
          :key="chat.id"
          :class="['saved-chat-item', { selected: currentChatId === chat.id }]"
          @click="$emit('loadChat', chat)"
          role="button"
          tabindex="0"
          :aria-label="'Load check: ' + chat.query"
          :aria-current="currentChatId === chat.id"
          @keydown.enter="$emit('loadChat', chat)"
        >
          {{ chat.query }}
        </div>
      </template>

      <template v-if="savedChats.yesterday.length">
        <h3 id="yesterday-checks">Yesterday</h3>
        <div
          v-for="chat in savedChats.yesterday"
          :key="chat.id"
          class="saved-chat-item"
          @click="$emit('loadChat', chat)"
          role="button"
          tabindex="0"
          :aria-label="'Load check: ' + chat.query"
          @keydown.enter="$emit('loadChat', chat)"
        >
          {{ chat.query }}
        </div>
      </template>

      <template v-if="savedChats.older.length">
        <h3 id="older-checks">Older</h3>
        <div
          v-for="chat in savedChats.older"
          :key="chat.id"
          class="saved-chat-item"
          @click="$emit('loadChat', chat)"
          role="button"
          tabindex="0"
          :aria-label="'Load check: ' + chat.query"
          @keydown.enter="$emit('loadChat', chat)"
        >
          {{ chat.query }}
        </div>
      </template>
    </div>
  </aside>
</template>

<script>
import { PanelLeftOpen, PanelLeftClose } from "lucide-vue-next";

export default {
  components: {
    PanelLeftOpen,
    PanelLeftClose,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    savedChats: {
      type: Object,
      required: true,
    },
    currentChatId: {
      type: [String, Number],
      default: null,
    },
  },

  emits: ["toggle", "loadChat"],
  
  data() {
    return {
      focusedIndex: -1,
      allChats: [],
      sections: ['today', 'yesterday', 'older']
    };
  },

  methods: {
    handleKeyNavigation(direction) {
      // Get all chat items
      const elements = document.querySelectorAll('.saved-chat-item');
      if (elements.length === 0) return;

      // Find currently focused element
      const focused = document.activeElement;
      let currentIndex = Array.from(elements).indexOf(focused);

      // If nothing is focused yet, start from beginning or end based on direction
      if (currentIndex === -1) {
        currentIndex = direction > 0 ? -1 : elements.length;
      }

      // Calculate new index
      const newIndex = currentIndex + direction;
      
      // Handle wrapping around
      if (newIndex >= elements.length) {
        elements[0].focus();
      } else if (newIndex < 0) {
        elements[elements.length - 1].focus();
      } else {
        elements[newIndex].focus();
      }
    },

    // Add method to handle initial focus
    handleFirstFocus() {
      const elements = document.querySelectorAll('.saved-chat-item');
      if (elements.length > 0) {
        elements[0].focus();
      }
    }
  }
};
</script>
