<template>
  <div class="terms-modal-overlay" 
       @click="$emit('update:show', false)"
       role="dialog"
       aria-labelledby="privacy-title"
       aria-modal="true">
    <div class="terms-modal-content" @click.stop>
      <button class="close-button" 
              @click="$emit('update:show', false)"
              aria-label="Close privacy policy">
        &times;
      </button>
      <h2 id="privacy-title">Privacy Policy</h2>
      <div class="terms-content">
        <h3>Introduction</h3>
        <p>
          This Privacy Policy explains how our fact-checking tool
          factually.co ("the Tool") handles information. We are committed
          to transparency and have designed the Tool to minimize data collection
          while providing effective fact-checking services.
        </p>

        <h3>Information Collection</h3>
        <p>The Tool collects the following information:</p>
        <ul>
          <li>The fact-checking queries submitted by users</li>
          <li>The responses provided by the Tool</li>
          <li>IP addresses for security and rate limiting purposes</li>
        </ul>

        <p>For authenticated users, we additionally collect:</p>
        <ul>
          <li>Email address for all users</li>
          <li>First and last name (only when signing in with Google)</li>
          <li>Check history associated with your account</li>
        </ul>

        <p>
          The Tool does not collect browser information, cookies, user location
          data, device information, usage patterns, or any other tracking data.
        </p>

        <h3>IP Address Usage</h3>
        <p>We collect IP addresses solely for:</p>
        <ul>
          <li>Enforcing rate limits (3 checks per minute per user)</li>
          <li>Preventing abuse and automated scraping</li>
          <li>Basic security monitoring</li>
          <li>Service reliability</li>
        </ul>
        <h3>Use of Information</h3>
        <p>
          We store queries and responses solely to improve the Tool's accuracy,
          identify common fact-checking needs, monitor for potential misuse, and
          maintain service quality. Query data is analyzed only in aggregate
          form to understand general usage patterns and improve the service.
        </p>

        <h3>Data Storage and Security</h3>
        <p>
          Although we collect minimal data, we implement basic security measures
          to protect stored queries from unauthorized access or alteration.
        </p>

        <h3>Authentication Data</h3>
        <p>
          We use Auth0 as our authentication provider. Account credentials and
          authentication data are handled securely through Auth0's services. We
          only store the minimal information necessary to maintain your account
          and check history.
        </p>

        <h3>Information Sharing</h3>
        <p>
          We do not share data with third parties, sell information to
          advertisers, transfer data to analytics services, or provide data to
          commercial entities. In the unlikely event of a legal requirement to
          disclose information, we would comply with valid legal orders,
          disclose only the specific information required, and notify users if
          legally permitted.
        </p>

        <h3>User Rights and Data Control</h3>
        <p>
          For non-authenticated users, we cannot identify specific user queries
          or provide individual data access due to our anonymous service model.
        </p>

        <p>Authenticated users can:</p>
        <ul>
          <li>Delete their account at any time</li>
          <li>Access their check history</li>
          <li>Delete their check history</li>
          <li>Request removal of all associated data</li>
        </ul>

        <h3>Changes to Privacy Policy</h3>
        <p>
          We may update this Privacy Policy to reflect changes in our practices.
          Updates will be posted on this page with a revised "Last Updated"
          date.
        </p>

        <h3>Contact Information</h3>
        <p>
          For questions about this Privacy Policy or the Tool's data practices,
          please contact
          <a href="mailto:admin@checkthatshit.com">admin@checkthatshit.com</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:show"],
};
</script>

<style scoped>
.terms-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 20px;
}

.terms-modal-content {
  background: #fcf5e6;
  width: 70%;
  max-width: 800px;
  height: 90%;
  border-radius: 12px;
  padding: 3rem;
  position: relative;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.terms-content {
  margin-top: 1rem;
}

.terms-content p {
  line-height: 1.8;
  text-align: left;
  font-size: 1rem;
  padding: 0 1rem;
}

.terms-content h2 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  text-align: left;
  padding: 0 1rem;
}

.terms-content p,
h3,
li {
}

.terms-content h3 {
  margin-top: 2rem;
}

.terms-content ul {
  margin-left: 2.5rem;
  margin-bottom: 1rem;
  list-style-type: disc;
}

@media (max-width: 480px) {
  .terms-content {
    font-size: 1rem;
  }
  .terms-modal-content {
    width: 100%;
    padding: 1rem;
  }
}
</style>
