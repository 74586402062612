<template>
    <div class="modal-overlay" 
         @click="$emit('update:show', false)"
         role="dialog"
         aria-labelledby="reddit-welcome-title"
         aria-modal="true">
        <div class="modal-content welcome-modal" @click.stop>
            <h2 id="reddit-welcome-title">Welcome to factually!</h2>
            <p class="welcome-text">Your personal bullshit detector for the digital age.</p>
            <div class="gif-container">
            </div>
            <p class="welcome-text">
                Simply paste any text you want to fact-check and let our AI-powered system analyze it for you.
            </p>
            <button class="send-button modal-send" @click="$emit('update:show', false)">Got it!</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true
        }
    },
    emits: ['update:show']
};
</script>

<style scoped>
.welcome-modal {
    max-width: 500px;
    text-align: center;
}

.welcome-text {
    margin: 1rem 0;
    color: #666;
    line-height: 1.6;
}

.gif-container {
    margin: 2rem 0;
    width: 100%;
}

.gif-container img {
    max-width: 100%;
    border-radius: 8px;
}
</style>
