import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../components/LoginView.vue";
import { createAuthGuard } from "@auth0/auth0-vue";

export default function createAppRouter(app) {
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: "/login",
        name: "Login",
        component: LoginView,
        beforeEnter: createAuthGuard(app),
        props: true,
      },
      {
        path: "/",
        name: "Home",
        component: () => import("../components/HomeView.vue"),
      },
      {
        path: "/fact-checks/:topic/:slug",
        name: "FactCheck",
        component: () => import("../components/CheckView.vue"),
        props: true
      },
      {
        path: "/checks/:id",
        name: "LegacyCheck",
        component: () => import("../components/CheckView.vue"),
        props: true
      },
      // Catch-all route - must be last
      {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("../components/NotFoundView.vue")
      }
    ]
  });

  return router;
}
