<template>
  <header class="fixed-header">
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Rozha+One&display=swap"
    />
    <div class="header-left">
      <div class="toggle-container">
        <h1 class="header-title">factually</h1>
        <button
          class="sidebar-toggle"
          :class="{ moved: isSidebarOpen }"
          @click="$emit('toggleSidebar')"
          aria-label="Toggle sidebar navigation"
          :aria-expanded="isSidebarOpen"
        >
          <PanelLeftClose v-if="isSidebarOpen" />
          <PanelLeftOpen v-else />
        </button>
        <a
          href="https://www.reddit.com/r/checkthatshit"
          target="_blank"
          rel="noopener noreferrer"
          class="reddit-link"
        >
          <strong aria-label="Visit our subreddit">/r/sub</strong>
        </a>
      </div>
    </div>
    <div class="header-buttons">
      <button v-if="isAuthenticated" 
              class="auth-button" 
              @click="logout"
              aria-label="Log out of your account">
        Logout
      </button>
      <button v-else 
              class="auth-button" 
              @click="login"
              aria-label="Log in to your account">
        Login
      </button>
    </div>
  </header>
</template>

<script>
import { PanelLeftOpen, PanelLeftClose } from "lucide-vue-next";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  components: {
    PanelLeftOpen,
    PanelLeftClose,
  },

  props: {
    isSidebarOpen: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["toggleSidebar", "showFeedback"],

  setup() {
    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

    return {
      isAuthenticated,
      login: () =>
        loginWithRedirect({
          appState: { targetUrl: window.location.pathname },
        }),
      logout: () =>
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        }),
    };
  },
};
</script>

<style scoped>
.fixed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.header-left {
  display: flex;
  align-items: center;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.sidebar-toggle {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.reddit-link {
  color: inherit;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-left: 3rem;
  font-family: "Lato", serif;
}

.reddit-link:hover {
  text-decoration: underline;
}

.feedback-button,
.auth-button {
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  border: none;
  border-radius: 0.8rem;
  background-color: lightcoral;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease;
  white-space: nowrap;
  margin-left: 0.5rem;
}

.auth-button:hover {
  background-color: #555;
  transform: translateY(-2px);
}
</style>
