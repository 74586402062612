<template>
  <div class="app-wrapper">
    <AppSidebar
      :is-open="isSidebarOpen"
      :saved-chats="savedChats"
      :current-chat-id="currentChat?.id"
      @toggle="toggleSidebar"
      @load-chat="loadSavedChat"
    />

    <div
      class="main-content"
      :class="{ 'sidebar-open': isSidebarOpen }"
      @click="handleMainContentClick"
    >
      <AppHeader
        :is-sidebar-open="isSidebarOpen"
        @toggle-sidebar="toggleSidebar"
        @show-feedback="showFeedbackModal = true"
      />

      <router-view @chat-saved="categorizeSavedChats" />

      <footer class="footer-links" role="contentinfo">
        <button class="terms-button" 
                @click="showTermsModal = true"
                aria-label="View terms and conditions">
          Terms & Conditions
        </button>
        <span class="separator" aria-hidden="true">|</span>
        <button class="terms-button" 
                @click="showPrivacyModal = true"
                aria-label="View privacy policy">
          Privacy Policy
        </button>
        <span class="separator" aria-hidden="true">|</span>
        <a
          href="https://buymeacoffee.com/seqpi650zd"
          target="_blank"
          rel="noopener noreferrer"
          class="terms-button"
          aria-label="Support us on Buy Me a Coffee"
        >
          Help out
        </a>
      </footer>
    </div>

    <FeedbackModal
      v-if="showFeedbackModal"
      v-model:show="showFeedbackModal"
      @submit="sendFeedback"
    />
    <WelcomeModal v-if="false" v-model:show="showWelcomeModal" />
    <TermsModal v-if="showTermsModal" v-model:show="showTermsModal" />
    <PrivacyModal v-if="showPrivacyModal" v-model:show="showPrivacyModal" />
  </div>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "./config";
import { trackVisit } from "./services/tracking";
import AppHeader from "./components/AppHeader.vue";
import AppSidebar from "./components/AppSidebar.vue";
import FeedbackModal from "./components/FeedbackModal.vue";
import WelcomeModal from "./components/WelcomeModal.vue";
import TermsModal from "./components/TermsModal.vue";
import PrivacyModal from "./components/PrivacyModal.vue";

export default {
  components: {
    AppHeader,
    AppSidebar,
    FeedbackModal,
    WelcomeModal,
    TermsModal,
    PrivacyModal,
  },

  computed: {
    shouldCloseSidebar() {
      return this.isSidebarOpen && window?.innerWidth <= 480;
    },
  },

  data() {
    return {
      isSidebarOpen: false,
      savedChats: {
        today: [],
        yesterday: [],
        older: [],
      },
      currentChat: null,
      showFeedbackModal: false,
      showWelcomeModal: false,
      showTermsModal: false,
      showPrivacyModal: false,
    };
  },

  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      this.saveSidebarState();
    },

    handleMainContentClick(event) {
      // Only close sidebar if clicking the overlay (main-content) directly
      if (
        event.target === event.currentTarget &&
        this.isSidebarOpen &&
        window.innerWidth <= 480
      ) {
        this.toggleSidebar();
      }
    },

    saveSidebarState() {
      localStorage.setItem("isSidebarOpen", this.isSidebarOpen);
    },

    loadSidebarState() {
      const saved = localStorage.getItem("isSidebarOpen");
      this.isSidebarOpen = saved === "true";
    },

    loadSavedChats() {
      const saved = localStorage.getItem("savedChats");
      if (saved) {
        const chats = JSON.parse(saved);
        this.categorizeSavedChats(chats);
      }
    },

    categorizeSavedChats(chats) {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      this.savedChats = {
        today: [],
        yesterday: [],
        older: [],
      };

      chats.forEach((chat) => {
        const chatDate = new Date(chat.datetime);
        if (chatDate >= today) {
          this.savedChats.today.push(chat);
        } else if (chatDate >= yesterday) {
          this.savedChats.yesterday.push(chat);
        } else {
          this.savedChats.older.push(chat);
        }
      });
    },

    loadSavedChat(chat) {
      this.currentChat = chat;
      this.$router.push(
        `/fact-checks/${chat.response.topic}/${chat.response.slug}`
      );
    },

    async sendFeedback(feedback) {
      try {
        await axios.post(`${API_BASE_URL}/feedback`, feedback);
        alert("Feedback sent successfully!");
      } catch (error) {
        alert("Failed to send feedback: " + error.message);
      }
    },

    checkFirstVisit() {
      const hasVisited = localStorage.getItem("hasVisitedBefore");
      if (!hasVisited) {
        this.showWelcomeModal = true;
        localStorage.setItem("hasVisitedBefore", "true");
      }
    },
  },

  created() {
    this.loadSavedChats();
    this.loadSidebarState();
    this.checkFirstVisit();

    // Check for origin parameter
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get("origin");
    if (origin) {
      trackVisit(origin);
    }
  },
};
</script>

<style scoped>
@import "./styles/base.css";
@import "./styles/animations.css";
@import "./styles/responsive.css";
</style>
