<template>
  <div class="login-container">
    <div class="login-box">
      <img src="../assets/logo.webp" alt="Logo" class="login-logo">
      <h1>Welcome to factually.co</h1>
      <p>Your personal bullshit detector for the digital age</p>
      <div v-if="error" class="error-message">
        {{ error.message }}
      </div>
      <button 
        @click="login" 
        class="login-button"
        :disabled="isLoading"
      >
        {{ isLoading ? 'Loading...' : 'Log In / Sign Up' }}
      </button>
    </div>
  </div>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'LoginView',
  setup() {
    const { loginWithRedirect, isLoading, error } = useAuth0();
    
    const login = async () => {
      try {
        await loginWithRedirect();
      } catch (err) {
        console.error('Login failed:', err);
      }
    };

    return {
      login,
      isLoading,
      error
    };
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fcf5e6;
  padding: 1rem;
}

.login-box {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.login-logo {
  width: 120px;
  height: 120px;
  margin-bottom: 1.5rem;
}

.login-button {
  background-color: lightcoral;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1.5rem;
  transition: all 0.2s ease;
}

.login-button:hover:not(:disabled) {
  background-color: #e06666;
  transform: translateY(-2px);
}

.login-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #f8d7da;
}

h1 {
  margin-bottom: 1rem;
  color: #333;
}

p {
  color: #666;
  margin-bottom: 1.5rem;
}
</style>
